import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import cx from 'classnames';
import ContactForm from '../../components/Forms/ContactForm';
import Page from '../../components/Page';
import styles from './ddsg.module.scss';

const onDownload = () => {
  if (typeof(window) !== 'undefined') {
    window.location = 'https://drive.google.com/file/d/1Mp_UthHD_dmDPegJkNs-IPWw03lFyhOV/view?usp=sharing';
  }
}

export const DataDrivenSourcing = ({
  seo,
  title,
  subtitle,
  atsOptions,
  sizeOptions,
  sourceOptions,
  howtos = [],
  location,
  cta,
  liltitle,
  text1,
  text2,
}) => (
  <Page seo={seo} location={location}>
    <div className={cx(styles.contact)}>

      <div className={cx(styles.heading)}>
        <div className={cx('container')}>
          <img className="m-0" src="/images/icons/v1/insights.svg" />
          &nbsp;&nbsp;&nbsp;
          <h1 className={cx('m-0')}>
            { title }
          </h1>
        </div>
      </div>

      <div className={cx('container', styles.contactFlex)}>
        <div className={cx(styles.stuff)}>
          { subtitle && <h2>{liltitle}</h2> }
          { subtitle && <h3>{subtitle}</h3> }

          <div>
            { text1 && <p>{text1}</p> }
            <ul>
              {howtos.map(({ item }) => (
                <li className='m-0'>{item}</li>
              ))}
            </ul>
            { text2 && <p>{text2}</p> }
          </div>
        </div>
        <div className={cx(styles.form)}>
          <ContactForm
            atsOptions={atsOptions}
            sizeOptions={sizeOptions}
            sourceOptions={sourceOptions}
            cta={cta}
            submitCallback={onDownload}
            successText="Success!"
          />
        </div>
      </div>
    </div>
  </Page>
);

const DataDrivenSourcingPage = ({data, location}) => {
  const { defaultData: { frontmatter: defaultFrontmatter },
  variantData: { frontmatter: variantFrontmatter } } = data || {};
  return (
    <DataDrivenSourcing
      location={location}
      {...defaultFrontmatter}
      {...variantFrontmatter}
    />
  );
};


DataDrivenSourcingPage.propTypes = {
  data: PropTypes.shape({
    defaultData: PropTypes.object.isRequired,
    variantData: PropTypes.object.isRequired,
  }).isRequired,
};

export default DataDrivenSourcingPage;

export const DataDrivenSourcingQuery = graphql`
  query DataDrivenSourcing {
    defaultData: markdownRemark(frontmatter: { name: { eq: "contact"} }) {
      frontmatter {
        atsOptions {
          option
        }
        sizeOptions {
          option
        }
        sourceOptions {
          option
        }
      }
    }
    variantData: markdownRemark(frontmatter: { name: { eq: "dataDrivenSourcing"} }) {
      frontmatter {
        seo {
          description
          title
        }
        title
        cta
        liltitle
        subtitle
        howtos {
          item
        }
        text1
        text2
      }
    }
  }
`;
