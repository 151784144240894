import React from 'react';
import PropTypes from 'prop-types';
import Helmet from "./SEO";
import { trackEvent } from '../utils';

class Page extends React.Component {
  componentDidMount() {
    const { location: { pathname } = {} } = this.props;
    trackEvent('Page Visited', {
      location: pathname || 'unknown'
    })
  }

  render() {
    const { seo, children } = this.props;
    return (
      <span>
        <Helmet options={seo} />
        {children}
      </span>
    )
  }
}

Page.propTypes = {
  children: PropTypes.node.isRequired,
  seo: PropTypes.shape({}),
  location: PropTypes.shape({}).isRequired,
}

Page.defaultProps = {
  seo: null,
}

export default Page;
